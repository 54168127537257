import {Loki, Collection} from '@inwink/inwinkdb';
import * as assign from 'lodash/assign';
import { Entities } from '@inwink/entities/entities';
import * as Data from '../../../data/index';
import { getRecommandations, nbRecosToShow } from './networking';
import { States } from '@@services/services';

export interface INotificationsDb {
    db: Loki;
    notifications: Collection<Entities.IUserNotification>;
    save(): Promise<any>;
}

export function getNotifications(eventid, userid) {
    let options;
    const Adapter = Data.getDefaultAdapter();

    const db = new Loki(InWinkEnv + "-" + eventid + '-' + userid + '-notifications.json', options);
    db.initializePersistence({
        adapter: Adapter ? new Adapter() : null
    });
    return db.loadDatabase(null, true).then(() => {
        let col = db.getCollection<Entities.IUserNotification>("notifications");
        if (!col) { col = db.addCollection<Entities.IUserNotification>("notifications", { unique: ['id'] }); }

        return {
            db,
            notifications: col,
            save() {
                return db.saveDatabase();
            }
        };
    });
}

export function getUnreadNotifications(notifs: Entities.IUserNotification[]) {
    return notifs.filter((n) => !n.dismissed).length;
}

export function syncNotifications(
    isRealtime: boolean,
    data: INotificationsDb,
    notifs: Entities.IUserNotification[],
    eventconf: Entities.IEventDetailConfiguration,
    userData: States.IPersonDataStore
) {
    const notifsData = [...notifs];
    for (let i = 0; i < data.notifications.data?.length; i++) {
        const notif = data.notifications.data[i];
        if (notifsData.map((n) => n.id).indexOf(notif.id) === -1) {
            data.notifications.remove(notif);
        }
    }

    if (notifsData?.length) {
        notifsData.forEach((n) => {
            const existing = data.notifications.data.find((nn) => nn.id === n.id);
            if (existing) {
                const dismissed = existing.dismissed ? true : n.dismissed;
                assign(existing, n, { dismissed });
                data.notifications.update(existing);
            } else {
                if (typeof window !== 'undefined' && window?.inwink?.toast?.add && isRealtime) {
                    const options = {
                        disabledAutoDismiss: true
                    };

                    if (n.notificationType !== "NewDiscussionThreadMessage" || !eventconf?.companion?.headerSettings?.enabledChat)
                        window.inwink.toast.add({ usernotification: n }, 'realTime', options);
                }

                data.notifications.insert(n);
            }
        });
    }


    const unreadThreadsCount = userData?.discussionThreads?.data?.filter?.((d) => d.hasUnread)?.length;
    const messageNotifications = data.notifications.data
        .filter((x) => x.notificationType === "NewDiscussionThreadMessage");

    const notifications = data.notifications.data
        .filter((x) => x.notificationType !== "NewDiscussionThreadMessage" || !eventconf?.companion?.headerSettings?.enabledChat);

    return {
        notificationsCount: getUnreadNotifications(notifications),
        // Accepted contact requests involve a new message in a thread 
        // but not a message notification on which unreadMessages is based. 
        messagesCount: Math.max(unreadThreadsCount, getUnreadNotifications(messageNotifications))
    };
}

export const computePendings = (props: any) => {
    let pendingInvitations = 0;
    let pendingRecommandations = 0;
    let pendingMessages = 0;
    let pendingNetworking = 0;
    let pendingMeetings = 0;
    let pendingAgendas = 0;

    const blocProperties = props.template && props.template.properties;
    const allowNetworking = props.user?.currentUser?.detail?.allowNetworking;
    const data = props.user?.currentUser?.data;
    if (data) {
        if (allowNetworking) {
            pendingInvitations = data.receivedContactRequests.data.filter((ct) => ct.status === "Requested").length;
            const recos = getRecommandations(props.user) || [];
            pendingRecommandations = recos.length;
        }
        pendingMeetings = data.meetings.data.filter((m) => {
            if (m.status === "Requested") {
                const owner = m.meetingPersons && m.meetingPersons.filter((p) => (p as any).isOwner)[0];
                if (owner && owner.person.id !== props.user.currentUser.detail.id) {
                    return true;
                }
            }

            return false;
        }).length;

        // Accepted contact requests involve a new message in a thread 
        // but not a message notification on which unreadMessages is based. 
        const unreadThreadsCount = data.discussionThreads.data.filter((d) => d.hasUnread).length;
        pendingMessages = Math.max(unreadThreadsCount, props.user?.currentUser?.data?.unreadMessages);

        if (pendingInvitations > 99) pendingInvitations = 99;
        if (pendingRecommandations > nbRecosToShow) pendingRecommandations = nbRecosToShow;
        if (pendingMessages > 99) pendingMessages = 99;

        pendingNetworking = pendingInvitations;
        if (blocProperties && blocProperties.networking && blocProperties.networking.showMeetings) {
            pendingNetworking += pendingMeetings;
        }

        if (blocProperties && blocProperties.agenda && blocProperties.agenda.enabled) {
            if (blocProperties.agenda.tabs && blocProperties.agenda.tabs.length) {
                blocProperties.agenda.tabs.forEach((t) => {
                    if (t.modules && t.modules.length) {
                        t.modules.forEach((tm) => {
                            if (tm.type === "networking.meetings") {
                                pendingAgendas += pendingMeetings;
                            }
                        });
                    }
                });
            } else {
                pendingAgendas = pendingMeetings;
            }
        }

        if (pendingNetworking > 99) pendingNetworking = 99;
    }

    return {
        pendingInvitations: pendingInvitations,
        pendingRecommandations: pendingRecommandations,
        pendingMessages: pendingMessages,
        pendingNetworking: pendingNetworking,
        pendingMeetings: pendingMeetings,
        pendingAgendas: pendingAgendas
    };
};
