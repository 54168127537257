import { Entities } from '@inwink/entities/entities';
import { VisualTheme } from '@inwink/entities/visualtheme';
import * as uniq from 'lodash/uniq';
import { States } from '../../../../services/services';

export { checkIfNetworkingAllowed } from '../../../../services/networkingservice';

export interface INetworkingSection {
    key : string;
    type: "domain" | "theme";
    values: INetworkingSectionValue[];
    label: VisualTheme.IAppLabel;
    selected: boolean;
}

export interface INetworkingSectionValue {
    key: string;
    label: VisualTheme.IAppLabel;
    search : boolean;
    propose: boolean;
}

export function getSortingForLanguage(language:string) {
    return (a, b) => {
        const aLabel = a && a.label && a.label[language];
        const bLabel = b && b.label && b.label[language];

        if (aLabel && (!bLabel || (aLabel < bLabel))) {
            return -1;
        }
        if (bLabel && (!aLabel || (bLabel < aLabel))) {
            return 1;
        }

        return 0;
    };
}

export function getPreferencesSections(
    currentLanguageCode: string,
    eventconf: Entities.IEventDetailConfiguration,
    preferences: Entities.IPersonNetworkingPreferences,
    networkingThemes: Entities.IEventTheme[],
    sortLabels?: boolean
) {
    const sort = getSortingForLanguage(currentLanguageCode);
    const definition = eventconf && eventconf.networkingDomains;
    const sections: INetworkingSection[] = [];
    const alreadyAddedEventThemes: string[] = [];
    if (definition && definition.categories && definition.categories.length) {
        definition.categories.forEach((c) => {
            let canBeAdded = true;
            let selected: any = preferences && preferences.domains && preferences.domains
                .filter((f) => f.categoryId === c.key)[0];
            let label = c.labels;
            if (c.eventThemeId) {
                alreadyAddedEventThemes.push(c.eventThemeId);
                const theme = networkingThemes?.filter((t) => t.id === c.eventThemeId)[0];
                if (theme) {
                    label = theme.title;
                    selected = preferences && preferences.themes && preferences.themes
                        .filter((f) => f.eventThemeId === c.eventThemeId)[0];
                } else {
                    canBeAdded = false;
                }
            }
            if (canBeAdded) {
                sections.push({
                    key: c.key,
                    type: c.eventThemeId ? "theme" : "domain",
                    label: label,
                    selected: !!selected,
                    values: c.domains.map((d) => {
                        const domain = definition.domains.filter((dom) => d === dom.key)[0];
                        const search = selected?.search?.indexOf(d) >= 0;
                        const propose = selected?.propose?.indexOf(d) >= 0;
                        if (!domain) {
                            return null;
                        }

                        return {
                            key: domain.key,
                            label: domain.labels,
                            search: search,
                            propose: propose
                        };
                    }).filter((d) => !!d).sort(sort)
                });
            }
        });
    }

    if (networkingThemes?.length) {
        networkingThemes.filter((theme) => alreadyAddedEventThemes.indexOf(theme.id) === -1).forEach((theme) => {
            const selected = preferences && preferences.themes && preferences.themes
                .filter((f) => f.eventThemeId === theme.id)[0];
            sections.push({
                key: theme.id,
                type: "theme",
                label: theme.title,
                selected: !!selected,
                values: []
            });
        });
    }
    if (sortLabels) {
        return sections.sort(sort);
    }
    return sections;
}

export function getSectionPrefsDetails(section: INetworkingSection) {
    const search = [];
    const propose = [];
    section?.values?.forEach((v) => {
        if (v.search) {
            search.push(v.key);
        }

        if (v.propose) {
            propose.push(v.key);
        }
    });

    return {
        search, propose
    };
}

export function sectionToPrefs(sections:INetworkingSection[], preferences:Entities.IPersonNetworkingPreferences) {
    let domains : Entities.IPersonNetworkingDomainPreferences[] = null;
    let themes : Entities.IPersonNetworkingThemePreferences[] = null;

    sections.forEach((s) => {
        if (s.selected) {
            const { search, propose } = getSectionPrefsDetails(s);
            if (s.type === "domain") {
                domains = domains || [];
                domains.push({
                    categoryId: s.key,
                    propose: propose,
                    search: search
                });
            } else if (s.type === "theme") {
                themes = themes || [];
                themes.push({
                    eventThemeId: s.key,
                    propose: propose,
                    search: search
                });
            }
        }
    });

    const newprefs = Object.assign({}, preferences, {
        domains: domains,
        themes: themes
    });

    return newprefs;
}

export const nbRecosToShow = 20;

export function getRecommandations(user: States.IAppUserState): Entities.IContactRecommandation[] {
    if (user && user.currentUser) {
        const data = user.currentUser.data;
        if (data.contactRecommandations && data.contactRecommandations.data.length) {
            return data.contactRecommandations.data.filter((reco) => {
                return !getContactRequest(data, reco.id);
            }).sort((a, b) => {
                return b.score - a.score;
            });
        }
    }

    return null;
}

export function jobTitles(data: States.IEventDataStore): boolean {
    let jobtitles;
    const sourceFields = data.fieldtemplates.data.find((t) => t.entityName === 'Registered');
    if (sourceFields) {
        const fld = sourceFields.template.fields.filter((s) => s.key === "function")[0];
        if (fld) {
            jobtitles = fld.valuesList.reduce((p, vl) => {
                const prev = p;
                prev[vl.key] = vl.labels;
                return prev;
            }, {} as any);
        }
    }

    return jobtitles;
}

export function getContactRequest(data: States.IPersonDataStore, personid: string): Entities.IContactRequest {
    if (data.receivedContactRequests && data.receivedContactRequests.data.length) {
        const ct = data.receivedContactRequests.data.filter((cr) => {
            return cr.personId === personid;
        })[0];

        if (ct) {
            return ct;
        }
    }

    if (data.sendedContactRequests && data.sendedContactRequests.data.length) {
        const ct = data.sendedContactRequests.data.filter((cr) => {
            return cr.targetPersonId === personid;
        })[0];

        if (ct) {
            return ct;
        }
    }

    return null;
}

export function getContacts(user: States.IAppUserState): Entities.IPerson[] {
    if (user && user.currentUser) {
        const data = user.currentUser.data;
        let res: Entities.IPerson[] = [];
        if (data.receivedContactRequests && data.receivedContactRequests.data.length) {
            const users = data.receivedContactRequests.data.filter((cr) => {
                return cr.status === "Accepted" && cr.person;
            }).map((cr) => cr.person);
            res = res.concat(users);
        }
        if (data.sendedContactRequests && data.sendedContactRequests.data.length) {
            const users = data.sendedContactRequests.data.filter((cr) => {
                return cr.status === "Accepted" && cr.targetPerson;
            }).map((cr) => cr.targetPerson);
            res = res.concat(users);
        }

        res = uniq(res, "id");

        return res.sort((a, b) => {
            if (a.lastname && b.lastname) {
                return a.lastname.localeCompare(b.lastname);
            } if (a.lastname && !b.lastname) {
                return 1;
            } if (!a.lastname && b.lastname) {
                return -1;
            }
            return 0;
        });
    }

    return null;
}

export function getContactsWithSendRequest(user: States.IAppUserState): Entities.IPerson[] {
    if (user?.currentUser) {
        const userData = user.currentUser?.data;
        let res: Entities.IPerson[] = [];
        const pendingContactRequests = userData.sendedContactRequests?.data || [];
        const requestedUsers = pendingContactRequests
            .filter((request) => request?.status === "Requested" && request?.targetPerson)
            .map((request) => request?.targetPerson);
        res.push(...requestedUsers);
        res = uniq(res, "id");
        const sortedUsers = res.sort((a, b) => (a?.lastname || '').localeCompare(b?.lastname || ''));
        return sortedUsers;
    }
    return null;
}